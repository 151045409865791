import Vue from "vue";
import VueRouter from "vue-router";
import inicio from "../views/inicio.vue";
// import sobre from "../views/sobre.vue"
// import aviso from "../views/Aviso.vue"
// import ayuda from "../views/ayuda.vue"
// import complaints from "../views/complaints.vue"

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    redirect: "/INICIO",
  },
  {
    path: "/INICIO",
    name: "inicio",
    component: inicio,
  },
  //   {
  //     path: '/about',
  //     name: 'about',
  //     component: sobre
  //   },
  // {
  //   path: '/notice',
  //   name: 'notice',
  //   component: aviso
  // },
  //   {
  //     path: '/help',
  //     name: 'help',
  //     component: ayuda
  //   },
  //   // {
  //   //   path: '/loan',
  //   //   name: 'loan',
  //   //   component: loan
  //   // },
  //   {
  //     path: '/complaints',
  //     name: 'complaints',
  //     component: complaints
  //   },
];
console.log(process.env);

const router = new VueRouter({
  mode: "hash",
  base: process.env.BASE_URL,
  routes,
});

export default router;
