<template>
  <div>
    <div class="header">
      <div class="left">
        <img src="../assets/image/logo.webp" alt="" />
        <p>
          Company Name: Hi-Fin lending inc.<br />
          Registration NO.CS201910995<br />
          CERTIFICATE OF AUTHORITY NO.3027
        </p>
      </div>
      <div class="right">
        <img src="../assets/image/header1.webp" alt="" class="header1" />
        <!-- <a
          href="https://play.google.com/store/apps/details?id=com.pesowalletapp.pro"
          target="_blank"
        >
          <img src="../assets/image/gp.webp" alt="" class="gp" />
        </a> -->
      </div>
    </div>
    <el-carousel trigger="click" class="banner_carousel">
      <el-carousel-item v-for="(item, index) in banner" :key="index">
        <img :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>
    <el-carousel trigger="click" class="banner1_carousel">
      <el-carousel-item v-for="(item, index) in banner1" :key="index">
        <img :src="item" alt="" />
      </el-carousel-item>
    </el-carousel>

    <div class="container">
      <div class="info">
        <div>
          <img src="../assets/image/info1.webp" alt="" />
          <span class="info_span">24-hour loan service</span>
          <span class="info_mobile_span"
            >24-hour loan<br />
            service</span
          >
        </div>

        <div>
          <img src="../assets/image/info2.webp" alt="" />
          <span>No Collateral</span>
        </div>

        <div>
          <img src="../assets/image/info3.webp" alt="" />
          <span class="info_span">
            Flexible loan amount<br />
            and term
          </span>
          <span class="info_mobile_span">
            Flexible loan<br />
            amount and<br />
            term
          </span>
        </div>

        <div>
          <img src="../assets/image/info4.webp" alt="" />
          <span>Lower interest</span>
        </div>
      </div>
      <div class="who">
        <h1>Who Are We?</h1>
        <div class="who_info">
          <div>
            <img src="../assets/image/who1.webp" alt="" />
            <div>
              <span>Financial services</span>
              <p>
                HI-FIN is the Philippines'<br />
                leading financial technology<br />
                platform, providing fast, seamless,<br />
                affordable financial services.
              </p>
            </div>
          </div>
          <div>
            <img src="../assets/image/who2.webp" alt="" />
            <div>
              <span>Safe and reliable</span>
              <p>
                HI-FIN is secure and reliable,<br />
                use's information is protected and<br />
                encrypted, which is fully compliance<br />
                with NPC regulations.
              </p>
            </div>
          </div>
        </div>
      </div>
      <div>
        <h1>Our products</h1>
        <div class="downloadBox" style="display:flex;">
          <a href="https://www.pesowallet-ph.com/">
            <img src="../assets/image/download1.png" alt="" target="_blank" />
          </a>
          <a href="https://www.creditcash-ph.com/#/home" target="_blank">
            <img src="../assets/image/download2.png" alt="" />
          </a>
        </div>
      </div>
      <!-- <div class="how">
        <h1>How to borrow money using HI-FIN?</h1>
        <img src="../assets/image/step.webp" alt="" class="step_img" />
        <img src="../assets/image/step1.webp" alt="" class="step1_img" />
      </div> -->
      <div class="faqs">
        <h1>FAQs</h1>
        <el-collapse v-model="activeNames" accordion>
          <div class="left">
            <el-collapse-item title="How do I apply for a loan?" name="1">
              <div>1. Log in to the any one of our Apps.</div>
              <div>2. Fill in Basic Information.</div>
              <div>3. Choose the loan amount and term.</div>
              <div>
                4. Money will be transferred to your account within 1 day.
              </div>
            </el-collapse-item>
            <el-collapse-item title="About Repayment?" name="3">
              <div>
                1. We will never provide any private account to users from calls
                or messages. Please do not make repayments on any private
                account that is not shown on the App.
              </div>
              <div>
                2. Please log in to the App to make repayment according to the
                steps/guidance shown in the App.
              </div>
            </el-collapse-item>
            <!-- <el-collapse-item title="Account Cancellation?" name="5">
              <div>
                Fill in your information in Complaint Details and fill in
                "account cancellation" in the Problem Description, then submit
                and wait for review. You can also contact customer service
                910-021-7007 or customer.service@HI-FIN-ph.com for review.
                After the review is approved, your account will be canceled
                within 5 working days.
              </div>
            </el-collapse-item> -->
          </div>
          <div>
            <el-collapse-item
              title="Interest rates and other charges?"
              name="2"
            >
              <div>Only below fees are charged:</div>
              <div>
                1. Interest fees of the loan, which is calculated since the day
                when money is disbursed into use's bank account/ewallet mobile.
              </div>
              <div>
                2. Service fees of the loan including the cost for information
                checking/ customer service/ payment gateway/ kyc service.
              </div>
              <div>
                3. Overdue penalty fees only happen when the installments of the
                loan are overdue.
              </div>
            </el-collapse-item>

            <el-collapse-item
              title=" How to remove customer data(including facebook information)"
              name="4"
            >
              <div>
                Fill in your information in Complaint Details and fill in
                "delete my data" in the Problem Description, then submit and
                wait for review. After the review is approved, your account data
                will be removed within 5 working days.
              </div>
            </el-collapse-item>

            <!-- <el-collapse-item title="How to contact us?" name="6">
              <div>1. Email: customer.service@HI-FIN-ph.com</div>
              <div>2. Mobile: 910-021-7007</div>
            </el-collapse-item> -->
          </div>
        </el-collapse>
      </div>
      <div class="com">
        <h1>Complaint Details</h1>
        <el-form ref="form" :model="form" :rules="rules">
          <el-form-item label="Name" prop="name">
            <el-input
              v-model="form.name"
              placeholder="Please enter your name"
              oninput="value=value.replace(/[/\d/]/ig,'')"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="Login Mobile Number" prop="mobile">
            <el-input
              v-model="form.mobile"
              oninput="value=value.replace(/\D/g, '')"
              :maxlength="11"
              placeholder="Please enter 11 digits starting from 09"
            ></el-input>
          </el-form-item>
          <el-form-item label="Loan ID">
            <el-input
              v-model="form.loanId"
              oninput="value=value.replace(/\D/g, '')"
              maxlength="25"
              placeholder="Please enter your loan id"
            ></el-input>
          </el-form-item>
          <el-form-item label="Problem Description" prop="problemDescription">
            <el-input
              type="textarea"
              v-model="form.problemDescription"
              :rows="4"
              maxlength="500"
              placeholder="Please enter the problem"
            ></el-input>
          </el-form-item>
          <p style="margin-top: -13px;">
            If you need to cancel your account, please submit your information
            here for review. After the review is approved, your account will be
            canceled within 5 working days.
          </p>
        </el-form>
        <div class="button">
          <img
            src="../assets/image/com_button.webp"
            alt=""
            @click="submitForm('form')"
          />
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="footer_container">
        <div class="footer_flex">
          <div class="left">
            <img src="../assets/image/logo_footer.webp" alt="" class="logo" />
            <div class="line"></div>
            <!-- <div>
              <img src="../assets/image/footer2.webp" alt="" />
              <span>910-021-7007</span>
            </div> -->
            <div>
              <img src="../assets/image/footer3.webp" alt="" />
              <span>biz-law@hifin-ph.com</span>
            </div>
            <!-- <a :href="url + '/Privacy.html'" target="_blank" class="privacy">
              <span>Privacy Policy</span>
            </a> -->
          </div>
        </div>
        <img src="../assets/image/footer1.webp" class="footer1" />
        <!-- <a
          href="https://play.google.com/store/apps/details?id=com.pesowalletapp.pro"
          target="_blank"
        >
          <img src="../assets/image/gp_footer.webp" class="gp" />
        </a> -->
      </div>
    </div>
  </div>
</template>

<script>
import common from "@/mixins/common.js";
// import "@/utils/setAdaption.js";
export default {
  mixins: [common],
  data() {
    var checkLogin = (rule, value, callback) => {
      if (value == "" || !value.startsWith("09") || value.length != 11) {
        callback(new Error("Please enter 11 digits starting from 09"));
      } else {
        callback();
      }
    };
    return {
      // val: null,
      banner: [
        require("../assets/image/swiper1.webp"),
        // require("../assets/image/swiper2.webp"),
        // require("../assets/image/swiper3.webp"),
      ],
      banner1: [
        require("../assets/image/swiper1_1.webp"),
        // require("../assets/image/swiper2_1.webp"),
        // require("../assets/image/swiper3_1.webp"),
      ],
      activeNames: [],
      form: {
        name: "",
        mobile: "",
        loanId: "",
        problemDescription: "",
      },
      rules: {
        name: [
          {
            required: true,
            trigger: "blur",
            message: "Please enter your name",
          },
        ],
        mobile: [
          {
            required: true,
            validator: checkLogin,
            trigger: "blur",
          },
        ],
        problemDescription: [
          {
            required: true,
            message: "Please enter the problem",
            trigger: "blur",
          },
        ],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          console.log(this.form);
          this.$axios
            .post(
              this.apiHead + "gateway/ApiService/api/v3/complaint/add",
              this.form
            )
            .then((res) => {
              if (res.data.code == 0) {
                this.form = {
                  name: "",
                  mobile: "",
                  loanId: "",
                  problemDescription: "",
                };
                // this.$message.success("Submitted");
                this.$message({
                  message: "Submitted", //
                  type: "success", // 设置消息类型，例如 success、warning、error 等
                  customClass: "my_message", // 添加自定义 class 名称
                });
              }
            });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.header {
  width: 14.4rem;
  margin: 0 auto;
}
.header,
.footer > .footer_container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.header {
  padding: 0.2rem 0;
  box-sizing: border-box;
  justify-content: inherit;
  > div {
    display: flex;
    align-items: center;
    // justify-content: inherit;
  }
  .left {
    img {
      width: 1.14rem;
      margin-right: 0.24rem;
    }
    p {
      font-size: 0.1rem;
      font-family: OpenSans, OpenSans;
      color: #5c6c99;
    }
  }
  .right {
    margin-left: 0.5rem;
    .header1 {
      width: 1.39rem;
      height: 0.45rem;
      margin-right: 0.64rem;
    }
    .gp {
      width: 1.54rem;
    }
  }
}
.el-carousel {
  /deep/.el-carousel__container {
    height: 6.9rem;
  }
  /deep/.el-carousel__arrow {
    width: 0.32rem;
    height: 0.32rem;
    i {
      display: none;
    }
  }
  /deep/.el-carousel__arrow--left {
    background: url("../assets/image/banner_left.webp") no-repeat;
    background-size: 100% 100%;
    left: 0.47rem;
  }
  /deep/.el-carousel__arrow--right {
    background: url("../assets/image/banner_left.webp") no-repeat;
    background-size: 100% 100%;
    right: 0.47rem;
    transform: rotate(180deg);
  }
  /deep/.el-carousel__indicators {
    bottom: 1.18rem;
    li {
      margin-right: 0.16rem;
      .el-carousel__button {
        width: 0.16rem;
        height: 0.16rem;
        background: rgba(255, 255, 255, 0.15);
        border-radius: 50%;
      }
    }
  }

  /deep/.el-carousel__indicator.is-active {
    button {
      background: #ffffff;
    }
  }
}
.banner1_carousel {
  display: none;
}
.container {
  width: 14.4rem;
  margin: -0.93rem auto;
  z-index: 999;
  position: relative;
  h1 {
    font-size: 0.42rem;
    font-family: OpenSans, OpenSans;
    font-weight: bold;
    color: #010e4b;
    margin-bottom: 0.68rem;
    margin-top: 0;
    text-align: center;
  }
  .info {
    display: flex;
    align-items: start;
    justify-content: space-between;
    background: #ffffff;
    border-radius: 0.16rem;
    box-shadow: 0.05rem 0.05rem 0.1rem 0px rgba(75, 75, 75, 0.16);
    padding: 0.31rem 0.95rem;
    box-sizing: border-box;
    margin-bottom: 0.88rem;
    > div {
      display: flex;
      flex-direction: column;
      align-items: center;
      img {
        width: 0.64rem;
        margin-bottom: 0.16rem;
      }
      span {
        font-size: 0.16rem;
        font-family: OpenSans, OpenSans;
        font-weight: 600;
        color: #010e4b;
        text-align: center;
      }
      .info_mobile_span {
        display: none;
      }
    }
  }
  .who {
    .who_info {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.76rem;
      > div {
        display: flex;
        align-items: center;
        img {
          width: 2.22rem;
          height: 1.56rem;
          margin-top: 0.3rem;
          margin-right: 0.24rem;
        }
        span {
          font-size: 0.28rem;
          font-family: Poppins, Poppins;
          font-weight: bold;
          color: #010e4b;
        }
        p {
          font-size: 0.22rem;
          font-family: OpenSans, OpenSans;
          font-weight: 600;
          color: #5c6c99;
          margin-top: 0.15rem;
          line-height: 0.3rem;
        }
      }
    }
  }
  .how {
    margin-bottom: 1.52rem;
    .step_img {
      display: block;
      width: 100%;
    }
    .step1_img {
      display: none;
    }
  }
  .faqs {
    .el-collapse {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border: none;
      margin-bottom: 1.76rem;
      .el-collapse-item {
        width: 6.16rem;
        min-height: 0.88rem;
        background: #ffffff;
        border-radius: 0.16rem;
        border: 0.01rem solid #979797;
        font-size: 0.22rem;
        font-family: PingFangSC, PingFang SC;
        font-weight: 600;
        color: #010e4b;
        padding: 0 0.32rem;
        box-sizing: border-box;
        margin-bottom: 0.4rem;
        /deep/.el-collapse-item__header {
          border: none;
          height: 0.88rem;
          font-size: 0.22rem;
          font-family: PingFangSC, PingFang SC;
          font-weight: 600;
          color: #010e4b;
          -webkit-tap-highlight-color: transparent !important;
          line-height: 0.3rem;
        }
        /deep/.el-collapse-item__content > div {
          font-size: 0.18rem;
          font-family: PingFangSC, PingFang SC;
          color: #333333;
          font-weight: 400;
        }
        /deep/.el-collapse-item__arrow {
          &::before {
            content: "";
            background: url("../assets/image/bottom.webp") no-repeat;
            width: 0.12rem;
            height: 0.08rem;
            background-size: 100% 100%;
            display: block;
          }
        }
        /deep/.el-collapse-item__arrow.is-active {
          transform: rotate(180deg);
        }
      }
    }
  }
  .downloadBox {
    justify-content: center;
    margin-bottom: 1.5rem;
    a {
      width: 2.64rem;
      height: 2.64rem;
      img {
        width: 100%;
        height: 100%;
      }
      &:first-child {
        margin-right: 3.12rem;
      }
    }
  }
  .com {
    .el-form {
      /deep/.el-form-item__label {
        margin-bottom: 0.1rem;
        font-size: 0.22rem;
        font-family: OpenSans, OpenSans;
        font-weight: 600;
        color: #010e4b;
      }
      .el-input,
      .el-textarea {
        /deep/.el-input__inner,
        /deep/.el-textarea__inner {
          border-radius: 0.1rem;
          border: 0.02rem solid #b3b3b3;
          font-size: 0.18rem;
          font-family: PingFangSC, PingFang SC;
          color: #5c6c99;
        }
      }
      /deep/.el-input__inner {
        height: 0.71rem;
      }
      /deep/.el-textarea__inner {
        padding-top: 0.2rem;
        padding-bottom: 0.2rem;
        box-sizing: border-box;
      }
      .el-form-item {
        margin-bottom: 0.56rem;
      }
      /deep/.el-form-item__error {
        font-size: 0.18rem;
        color: #ff0000;
        margin-top: 10px;
      }
    }
    p {
      font-size: 0.18rem;
      font-family: PingFangSC, PingFang SC;
      color: #5c6c99;
    }
    .button {
      display: flex;
      justify-content: center;
      margin: 0.56rem 0 0.5rem;
      img {
        width: 3.46rem;
        cursor: pointer;
      }
    }
  }
}
.footer {
  margin-top: 1.38rem;
  padding: 0.48rem 0 0.38rem;
  box-sizing: border-box;
  background: #000000;
  .line {
    width: 1.06rem;
    height: 0.04rem;
    background: #5057f4;
    border-radius: 0.02rem;
    margin: 0.24rem 0 0.32rem;
  }
  .footer_container {
    justify-content: space-around;
    .footer_flex {
      display: flex;
      align-items: center;
      .left {
        margin-right: 0.27rem;
        .logo {
          width: 1.86rem;
          height: 1.04rem;
        }
        > div {
          display: flex;
          align-items: center;
          margin-bottom: 0.24rem;
          img {
            width: 0.18rem;
            height: 0.18rem;
            margin-right: 0.16rem;
          }
          span {
            font-size: 0.18rem;
            font-family: OpenSans, OpenSans;
            color: #ffffff;
          }
        }
      }
    }
  }

  .footer1 {
    width: 5.76rem;
    height: 0.88rem;
  }
  .gp {
    width: 2.52rem;
    height: 0.66rem;
  }
  .privacy {
    display: block;
    text-decoration: underline;
    margin-top: 0.32rem;
    font-size: 0.16rem;
    font-family: PingFangSC, PingFang SC;
    color: #5057f4;
  }
}

@media all and (max-width: 720px) {
  .header {
    height: 0.56rem;
    padding: 0.08rem 0.4rem;
    width: 100%;
    .left {
      img {
        width: 1.14rem;
      }
      p {
        display: none;
      }
    }

    .right {
      display: none;
    }
  }
  .el-carousel {
    /deep/.el-carousel__container {
      height: 2.58rem;
    }
    /deep/.el-carousel__arrow {
      width: 0.18rem;
      height: 0.18rem;
    }
    /deep/.el-carousel__indicators {
      display: none;
    }
    /deep/.el-carousel__arrow--left {
      left: 0.24rem;
    }
    /deep/.el-carousel__arrow--right {
      right: 0.24rem;
    }
  }
  .banner_carousel {
    display: none;
  }
  .banner1_carousel {
    display: block;
  }
  .container {
    width: 6.4rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0.24rem auto;
    h1 {
      font-size: 0.32rem;
      margin-bottom: 0.2rem;
    }

    .info {
      width: 100%;
      padding: unset;
      margin-bottom: 0.68rem;
      background: none;
      border-radius: 0;
      box-shadow: none;
      > div {
        img {
          width: 0.49rem;
          margin-bottom: 0.08rem;
        }
        span {
          font-size: 0.2rem;
          font-weight: 400;
        }
        .info_span {
          display: none;
        }
        .info_mobile_span {
          display: block;
        }
      }
    }
    .who {
      .who_info {
        flex-direction: column;
        margin-bottom: 0.8rem;
        > div {
          display: flex;
          align-items: center;
          img {
            width: 2.32rem;
            margin-right: 0.16rem;
            margin-top: 0.2rem;
          }
          div {
            span {
              font-size: 0.24rem;
            }
            p {
              font-size: 0.2rem;
              font-weight: 400;
              margin-top: 0.08rem;
            }
          }

          &:first-child {
            margin-bottom: 0.4rem;
          }
        }
      }
    }
    .how {
      margin-bottom: 0.8rem;
      h1 {
        margin-bottom: 0.28rem;
      }
      .step_img {
        display: none;
      }
      .step1_img {
        display: block;
        padding: 0 0.18rem;
        box-sizing: border-box;
      }
    }
    .com {
      display: none;
    }
    .faqs {
      .el-collapse {
        margin-bottom: 0;
        justify-content: center;
        .el-collapse-item {
          width: 6.4rem;
          min-height: 0.7rem;
          margin-bottom: 0.32rem;
          border-radius: 0.08rem;
          padding: 0 0.25rem;
          /deep/.el-collapse-item__header {
            height: 0.7rem;
            font-size: 0.24rem;
          }
          /deep/.el-collapse-item__arrow {
            &::before {
              content: "";
              width: 0.18rem;
              height: 0.11rem;
            }
          }
          /deep/.el-collapse-item__content > div {
            font-size: 0.24rem;
          }
        }
      }
    }
  }
  .footer {
    margin-top: 0;
    padding: 0.24rem 0.4rem;
    .line {
      display: none !important;
    }
    .footer_container {
      justify-content: flex-start;
      .left {
        margin-right: 0 !important;
        .logo {
          width: 1.86rem !important;
          margin-bottom: 0.26rem;
        }
        > div {
          margin-bottom: 0.14rem !important;
          img {
            width: 0.26rem !important;
            height: 0.26rem !important;
            margin-right: 0.1rem !important;
          }
          span {
            font-size: 0.22rem !important;
          }
        }
      }
    }
    .privacy {
      margin-top: 0.18rem;
      font-size: 0.22rem;
    }
    .footer1,
    .gp {
      display: none;
    }
  }
}
</style>
<style>
.my_message {
  min-width: 150px !important;
  height: 150px;
  background-color: rgba(0, 0, 0, 0.7) !important;
  border-radius: 8px !important;
  flex-direction: column;
  align-items: center;
  top: 50% !important;
  transform: translate(-50%, -50%) !important;
  justify-content: center;
  padding: 16px !important;
  box-sizing: border-box;
}
.my_message .el-icon-success {
  color: #fff !important;
  font-size: 46px;
  margin-right: 0 !important;
}
.my_message .el-message__content {
  margin-top: 18px;
  font-size: 24px;
  color: #fff !important;
}
</style>
