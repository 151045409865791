export default {
  data() {
    return {
      params: {
        account_token: "", //
        source: "",
      },
      apiHead: "",
      url: "",
    };
  },
  mounted() {
    this.getApiHead();
    window.addEventListener("resize", this.csstorem);
  },

  beforeDestroy() {
    window.removeEventListener("resize", this.csstorem);
  },
  methods: {
    getApiHead() {
      if (location.href.indexOf("//www.hifin-ph.com") >= 0) {
        this.apiHead = "https://apiprd.hifin-ph.com/"; //prod
        this.url = "https://www.hifin-ph.com";
      } else {
        this.apiHead = "https://api-test-ph.jinglewill.com/"; //test
        this.url = "https://hifin-ph.jinglewill.com";
      }
    },
    csstorem() {
      var oWidth =
        document.body.clientWidth || document.documentElement.clientWidth;
      window.onload = function() {
        if (oWidth <= 720) {
          console.log(oWidth);
          getRem(720, 100);
        } else {
          getRem(1920, 100);
        }
      };
      window.onresize = function() {
        if (oWidth <= 720) {
          getRem(720, 100);
        } else {
          getRem(1920, 100);
        }
      };

      function getRem(pwidth, prem) {
        var html = document.getElementsByTagName("html")[0];
        console.log(oWidth);
        console.log(pwidth);
        html.style.fontSize = (oWidth / pwidth) * prem + "px";
      }
    },
  },
  updated() {
    this.csstorem();
  },
};
